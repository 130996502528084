import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../trans.css';
import HomeDashboard from './HomeDashboard/HomeDashboard';
import AddQuestion from './AddQuestion/AddQuestion';
import QuestionLottery from './Lottery/QuestionLottery';
import AdminProfile from './AdminProfile/AdminProfile';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import avatar from '../Generalfiles/images/avatar.svg';
import logo2 from '../Generalfiles/images/logo.svg';
import { BsCardList } from 'react-icons/bs';
import { CgCloseO } from 'react-icons/cg';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import { FiLogOut } from 'react-icons/fi';
import { FetchAuthorization_API, AdminLogout_API } from '../../API/API';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
import Websitesettings from './Websitesettings/Websitesettings';
const Dashboardrouter = (props) => {
    let history = useHistory();
    const FetchAuthorizationQuery = useQuery(['FetchAuthorization_API'], () => FetchAuthorization_API(), { keepPreviousData: true, staleTime: Infinity });
    const LogoutMutation = useMutation('AdminLogout_API', {
        mutationFn: AdminLogout_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                FetchAuthorizationQuery.refetch();
                // history.push('/dashboard/institute/orders/showorder/' + data.data.orderid);
                // NotificationManager.success('', lang.orderisaddedsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    return (
        <div class="row m-0 w-100" style={{ background: '#f2f5fa' }}>
            <div class="col-lg-2 col-md-12 p-0" id="sidenavContainer" style={{ position: 'relative' }}>
                <div class={`${generalstyles.sidenav} ${generalstyles.app_sidebar}`}>
                    <div class="row m-0 w-100 d-flex pt-3">
                        <div
                            class="col-lg-12 col-md-6 d-flex align-items-center justify-content-center justify-content-md-start"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                history.push('/dashboard');
                            }}
                        >
                            <img src={logo2} style={{ maxWidth: '70%' }} />
                        </div>
                        <div
                            class="col-md-6 d-none d-md-flex align-items-center justify-content-end"
                            onClick={() => {
                                var element = document.getElementById('sidenavContainer');
                                element.classList.remove(generalstyles.sidebar_mobile_open);
                            }}
                        >
                            <CgCloseO size={30} />
                        </div>
                        <div class="col-lg-12 p-0 mt-1 mb-1 mb-md-4">
                            <hr />
                        </div>
                        {FetchAuthorizationQuery?.data?.data?.loggedin && (
                            <>
                                <div
                                    class={
                                        `${generalstyles.sidenavpage} ${generalstyles.activepage}` +
                                        ' col-lg-12 d-flex align-items-center justify-content-md-center p-md-0 pl-5 pr-5 primaryfontlight mb-5 '
                                    }
                                    style={{ fontSize: '18px' }}
                                    onClick={() => {
                                        history.push('/dashboard');
                                    }}
                                >
                                    <i class="h-100 allcentered pt-1">
                                        <BsCardList />
                                    </i>
                                    <span class="mr-4 primaryfontregular">الاسئلة</span>
                                </div>
                                <div
                                    class={
                                        `${generalstyles.sidenavpage} ${generalstyles.activepage}` +
                                        ' col-lg-12 d-flex align-items-center justify-content-md-center p-md-0 pl-5 pr-5 primaryfontlight mb-5 '
                                    }
                                    style={{ fontSize: '18px' }}
                                    onClick={() => {
                                        history.push('/dashboard/websitesettings');
                                    }}
                                >
                                    <i class="h-100 allcentered pt-1">
                                        <BsCardList />
                                    </i>
                                    <span class="mr-4 primaryfontregular">الاعدادات</span>
                                </div>
                                {/* <div
                                    class={`${generalstyles.sidenavpage}` + ' col-lg-12 d-flex align-items-center justify-content-md-center p-md-0 pl-5 pr-5 primaryfontlight '}
                                    style={{ fontSize: '18px', color: '#c5bcba' }}
                                    onClick={() => {
                                        history.push('/dashboard/lottery');
                                    }}
                                >
                                    <i class="h-100 allcentered pt-1">
                                        <FaTrophy />
                                    </i>
                                    <span class="mr-4">السحب العشوائى</span>
                                </div> */}
                                <div
                                    class={`${generalstyles.sidenavpage}` + ' col-lg-12 d-flex align-items-center justify-content-md-center p-md-0 pl-5 pr-5 primaryfontlight mt-5'}
                                    style={{ fontSize: '18px', color: '#c5bcba' }}
                                    onClick={() => {
                                        LogoutMutation.mutate();
                                    }}
                                >
                                    {LogoutMutation.isLoading && <span>...تحميل</span>}
                                    {!LogoutMutation.isLoading && (
                                        <>
                                            <i class="h-100 allcentered pt-1">
                                                <FiLogOut />
                                            </i>
                                            <span class="mr-4 primaryfontregular"> تسجيل الخروج</span>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div class="col-lg-10 p-0">
                <div class="row m-0 w-100">
                    <div class="col-lg-12 bg-white d-flex align-items-center justify-content-end" style={{ height: '70px' }}>
                        <div
                            class="col-lg-6 d-none d-md-flex justify-content-start"
                            onClick={() => {
                                var element = document.getElementById('sidenavContainer');
                                element.classList.add(generalstyles.sidebar_mobile_open);
                            }}
                        >
                            <HiOutlineMenuAlt3 size={25} />
                        </div>
                        <div class="col-lg-6 d-flex justify-content-end">
                            <img src={avatar} class={`${generalstyles.sidenavatar}` + ' float-left '} />

                            {/* <Dropdown>
                                <Dropdown.Toggle>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                   
                                    <Dropdown.Item
                                        onClick={() => {
                                            LogoutMutation.mutate();
                                        }}
                                    >
                                        تسجيل الخروج
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown> */}
                        </div>
                    </div>

                    <div class="col-lg-11 ml-auto mr-auto mt-5">
                        <div class="row m-0 w-100 pt-4 pb-3 pl-3 pr-3 bg-white ">
                            <Router>
                                <div class="col-lg-12 p-0">
                                    <Route
                                        render={({ location, match }) => {
                                            return (
                                                <TransitionGroup>
                                                    <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                        <Switch key={location.key} location={location}>
                                                            <Route
                                                                exact
                                                                path="/dashboard"
                                                                render={() => {
                                                                    if (FetchAuthorizationQuery.isSuccess && FetchAuthorizationQuery.data.data.loggedin) {
                                                                        return <HomeDashboard />;
                                                                    } else {
                                                                        return <Redirect to="/adminlogin" />;
                                                                    }
                                                                }}
                                                            />

                                                            {FetchAuthorizationQuery?.data?.data?.loggedin && (
                                                                <>
                                                                    <Route exact path="/dashboard/websitesettings" component={Websitesettings} />
                                                                    <Route exact path="/dashboard/adminprofile" component={AdminProfile} />
                                                                    <Route exact path="/dashboard/question/:typeparams/:idparams" component={AddQuestion} />
                                                                    <Route path="/dashboard/lottery/:questionidparams/:fromdateparams/:todateparams" component={QuestionLottery} />
                                                                </>
                                                            )}
                                                        </Switch>
                                                    </CSSTransition>
                                                </TransitionGroup>
                                            );
                                        }}
                                    />
                                </div>
                            </Router>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboardrouter;
