import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { AddQuestion_API, FetchQuestionInfo_API, CRUDAnswers_API, FetchQuestions_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { serverbaselink } from '../../../Env_Variables';
const AdminProfile = () => {
    let history = useHistory();
    return (
        <div class="row m-0 w-100">
            <div class="col-lg-12 d-flex justify-content-start p-sm-0 mt-3">
                <p class="m-0 p-0 d-flex align-items-center text-secondary" style={{ color: '#263a5b', fontSize: '30px' }}>
                    <FiArrowRight
                        size={25}
                        class="ml-3 cursor-pointer"
                        onClick={() => {
                            history.push('/dashboard');
                        }}
                    />
                    الملف الشخصي
                </p>
            </div>
            <div class="col-lg-12 d-flex justify-content-start mt-4 pt-4 pb-4 mb-4" style={{ border: '1px solid #eee' }}>
                <div class="row m-0 w-100">
                    <div class="col-lg-6 col-md-6 col-sm-12 mb-sm-4">
                        <div class="col-lg-12 d-flex flex-column justify-content-start">
                            <p class="d-flex justify-content-start mb-1">البريد الإلكتروني</p>
                        </div>
                        <input class={`${generalstyles.form_control2}`} type="text" />
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="col-lg-12 d-flex flex-column justify-content-start">
                            <p class="d-flex justify-content-start mb-1">كلمه السر</p>
                        </div>
                        <input class={`${generalstyles.form_control2}`} type="password" />
                    </div>
                    <div class="col-lg-12 d-flex justify-content-start mt-4">
                        <button class={`${generalstyles.btn} ${generalstyles.btn_success}`}>حفظ</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AdminProfile;
