import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    english: 'English',
                    arabic: 'Arabic',
                },

                ar: {
                    english: 'لغه إنجليزيه',
                    arabic: 'لغه عربيه',
                },
            },
            langdetect: 'ar',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            this.setState({
                langdetect: 'en',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
        } else {
            this.setState({
                langdetect: 'en',
            });
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        lang = this.state.lang.ar;
        document.body.setAttribute('dir', 'rtl');
        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
