import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './trans.css';
import generalstyles from './components/Generalfiles/CSS_GENERAL/general.module.css';
import Home from './components/Website/Home/Home';
import Dashboardrouter from './components/Dashabord/Dashboardrouter';
import Subscribe from './components/Website/Subscribe/Subscribe';
import './components/Generalfiles/CSS_GENERAL/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Login from './components/Login/Login';
import { FetchAuthorization_API } from './API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Winner from './components/Dashabord/Winner/Winner';
const App = (props) => {
    const FetchAuthorizationQuery = useQuery(['FetchAuthorization_API'], () => FetchAuthorization_API(), { keepPreviousData: true, staleTime: Infinity });

    return (
        <div class="row m-0 w-100 ">
            <Router>
                <div style={{ height: '100%', width: '100vw' }}>
                    <div class={generalstyles.app_container + ' w-100  '}>
                        <div class={`${generalstyles.app_main_inner}` + ' '}>
                            <Route
                                render={({ location, match }) => {
                                    return (
                                        <TransitionGroup>
                                            <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                <Switch>
                                                    <Route
                                                        exact
                                                        path="/"
                                                        render={() => {
                                                            return <Redirect to="/home" />;
                                                        }}
                                                    />
                                                    <Route exact path="/home" component={Home} />
                                                    <Route
                                                        exact
                                                        path="/winner/:questionidparams/:fromdateparams/:todateparams"
                                                        render={() => {
                                                            if (FetchAuthorizationQuery.isSuccess) {
                                                                if (FetchAuthorizationQuery.data.data.loggedin) {
                                                                    return <Winner />;
                                                                } else {
                                                                    return <Redirect to="/adminlogin" />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route exact path="/subscribe" component={Subscribe} />

                                                    <Route
                                                        path="/dashboard"
                                                        render={() => {
                                                            if (FetchAuthorizationQuery.isSuccess) {
                                                                if (FetchAuthorizationQuery.data.data.loggedin) {
                                                                    return <Dashboardrouter />;
                                                                } else {
                                                                    return <Redirect to="/adminlogin" />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                    <Route
                                                        path="/adminlogin"
                                                        render={() => {
                                                            if (FetchAuthorizationQuery.isSuccess) {
                                                                if (FetchAuthorizationQuery.data.data.loggedin) {
                                                                    return <Redirect to="/dashboard" />;
                                                                } else {
                                                                    return <Login />;
                                                                }
                                                            }
                                                        }}
                                                    />
                                                </Switch>
                                            </CSSTransition>
                                        </TransitionGroup>
                                    );
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Router>
        </div>
    );
};

export default App;
