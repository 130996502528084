import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import { FiLayers } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { FetchAuthorization_API, FetchQuestionWinner_API, AssignWinner_API, FetchQuestions_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
const QuestionLottery = () => {
    let history = useHistory();
    const { questionidparams, fromdateparams, todateparams } = useParams();
    const FetchQuestionWinnerQuery = useQuery(
        ['FetchQuestionWinner_API' + questionidparams + fromdateparams + todateparams],
        () => FetchQuestionWinner_API({ questionid: questionidparams, fromdate: fromdateparams, todate: todateparams }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    );
    const FetchQuestionsQuery = useQuery(['FetchQuestions_API'], () => FetchQuestions_API(), { keepPreviousData: true, staleTime: Infinity });

    const AssignWinnerMutation = useMutation('AssignWinner_API', {
        mutationFn: AssignWinner_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                FetchQuestionsQuery.refetch();
                history.push('/dashboard');
                NotificationManager.success('', 'Question Answers Updated');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    return (
        <div class="row m-0 w-100">
            {FetchQuestionWinnerQuery.isFetching && (
                <div class="w-100 text-center" style={{ height: 300 }}>
                    <CircularProgress color={'#ccc'} width="40px" height="40px" />
                </div>
            )}
            {!FetchQuestionWinnerQuery.isFetching && FetchQuestionWinnerQuery.isSuccess && (
                <div class="col-lg-12 d-flex justify-content-start">
                    <div class="row m-0 w-100 mt-3">
                        <div class="col-lg-12 d-flex flex-column justify-content-start">
                            <p class="m-0 p-0 d-flex align-items-center text-secondary primaryfontregular" style={{ fontSize: '30px' }}>
                                السحب العشوائي للجائزة
                            </p>
                            {/* <p class="m-0 p-0 d-flex align-items-center mt-3 primaryfontlight" style={{ color: '#8a98ac', fontSize: '20px' }}>
                            برجاء اختيار السؤال أو رقم السؤال لبدء السحب العشوائي
                        </p> */}
                        </div>
                        {FetchQuestionWinnerQuery.data.data.randomwinner != null && (
                            <div class="col-lg-12 d-flex justify-content-start mt-4 pt-4 pb-4" style={{ border: '1px solid #eee' }}>
                                <div class="row m-0 w-100">
                                    <div class="col-lg-12 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-1">السؤال</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input class={`${generalstyles.form_control2}`} disabled={true} value={FetchQuestionWinnerQuery.data.data.randomwinner.QuestionText} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-1">الاسم</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input class={`${generalstyles.form_control2}`} disabled={true} value={FetchQuestionWinnerQuery.data.data.randomwinner.name} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-1">رقم المحمول</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input class={`${generalstyles.form_control2}`} disabled={true} value={FetchQuestionWinnerQuery.data.data.randomwinner.phone} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-1">الاجابة</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input class={`${generalstyles.form_control2}`} disabled={true} value={FetchQuestionWinnerQuery.data.data.randomwinner.UserAnswer} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                        <div class="row m-0 w-100">
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-1">التاريخ</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input class={`${generalstyles.form_control2}`} disabled={true} value={FetchQuestionWinnerQuery.data.data.randomwinner.Answeredat} />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 d-flex align-items-center justify-content-center mt-4 p-sm-0">
                                        <button
                                            class={`${generalstyles.btn} ${generalstyles.btn_primary}` + ' mr-2 ml-2'}
                                            onClick={() => {
                                                FetchQuestionWinnerQuery.refetch();
                                            }}
                                        >
                                            اعادة السحب
                                        </button>
                                        <button
                                            class={`${generalstyles.btn} ${generalstyles.btn_success}`}
                                            onClick={() => {
                                                if (window.confirm('Are you sure you want to assign this winner?')) {
                                                    AssignWinnerMutation.mutate({
                                                        useranswerid: FetchQuestionWinnerQuery.data.data.randomwinner.UserAnswerid,
                                                        questionid: questionidparams,
                                                    });
                                                }
                                            }}
                                            disabled={AssignWinnerMutation.isLoading}
                                        >
                                            تاكيد الفائز
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {FetchQuestionWinnerQuery.data.data.randomwinner == null && (
                            <div class="col-lg-12 allcentered flex-column" style={{ height: 200 }}>
                                <FiLayers size={25} />
                                <p style={{ fontSize: '25px' }} class="primaryfontmedium">
                                    لا يوجد
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};
export default QuestionLottery;
