import React, { useEffect, useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import generalstyles from "../../Generalfiles/CSS_GENERAL/general.module.css";
import image1 from "../../Generalfiles/images/slider04.jpg";
import image2 from "../../Generalfiles/images/slider.jpg";
import image3 from "../../Generalfiles/images/slider03.jpg";
import slider from "../../Generalfiles/images/slider02.jpg";
import logo from "../../Generalfiles/images/logo.svg";
import visionlogo from "../../Generalfiles/images/visionlogo.svg";
import { FaYoutube, FaSnapchat, FaUserCheck, FaBell } from "react-icons/fa";
import { FiArrowDown } from "react-icons/fi";
import { AiOutlineTwitter } from "react-icons/ai";
import avatar from "../../Generalfiles/images/avatar.jpeg";
import AOS from "aos";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { FetchWebsiteinfo_API } from "../../../API/API";
import ReactPlayer from "react-player";
import "aos/dist/aos.css";
import { Follow } from "react-twitter-widgets";
AOS.init();
const Home = () => {
  let history = useHistory();
  const [endTime, setendTime] = useState("");
  const [remainingTime, setremainingTime] = useState("");
  const [remaining, setremaining] = useState({
    days: 0,
    hours: 0,
  });
  const FetchWebsiteinfoQuery = useQuery(
    ["FetchWebsiteinfo_API"],
    () => FetchWebsiteinfo_API(),
    { keepPreviousData: true, staleTime: Infinity }
  );
  // use UNIX timestamp in seconds
  var DateDiff = {
    inDays: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    },
    inHours: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    },

    inWeeks: function (d1, d2) {
      var t2 = d2.getTime();
      var t1 = d1.getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    inMonths: function (d1, d2) {
      var d1Y = d1.getFullYear();
      var d2Y = d2.getFullYear();
      var d1M = d1.getMonth();
      var d2M = d2.getMonth();

      return d2M + 12 * d2Y - (d1M + 12 * d1Y);
    },

    inYears: function (d1, d2) {
      return d2.getFullYear() - d1.getFullYear();
    },
  };
  useEffect(() => {
    if (FetchWebsiteinfoQuery.isSuccess) {
      if (FetchWebsiteinfoQuery.data.data.currentquestion != null) {
        const stratTime = new Date();
        var endt = new Date(
          FetchWebsiteinfoQuery.data.data.currentquestion.expirydate.replace(
            " ",
            "T"
          )
        );
        var indays = DateDiff.inDays(stratTime, endt);

        setremaining({
          days: indays,
          hours: indays,
        });
        setendTime(endt);
        setremainingTime(endt - stratTime);
      }
    }
  }, [FetchWebsiteinfoQuery.isSuccess]);
  const [slideShowImages, setslideShowImages] = useState([
    {
      url: image1,
      caption1: "جوائزها اكثر من",
      caption2: "2,000,000",
      caption3: "ريال سعودى",
    },
    {
      url: image2,
      caption1: "الجائزة الكبرى",
      caption2: "1,000,000",
      caption3: "ريال سعودى",
    },
    {
      url: image3,
      caption1: "شهرياً",
      caption2: "100,000",
      caption3: "ريال سعودى",
    },
  ]);
  const Slideshowsettings = {
    indicators: false,
    arrows: true,
    pauseOnHover: false,
    scale: 1.4,
  };
  return (
    <div class="row m-0 w-100">
      {/* Header */}
      <div
        class="col-lg-9 col-md-9 col-sm-9 p-0 m-auto"
        style={{ position: "relative" }}
      >
        <div
          class={
            `${generalstyles.headerWrapper}` +
            " d-flex w-100 align-items-center justify-content-center"
          }
        >
          <img src={logo} class={`${generalstyles.logo}` + " ml-3 "} />
          <img
            src={visionlogo}
            class={`${generalstyles.visionlogo}` + " mr-4 "}
          />
        </div>
      </div>
      <div
        class={"col-lg-12 p-0 d-flex justify-content-center"}
        style={{ height: "90vh" }}
        data-aos="fade-down"
      >
        <div
          style={{
            position: "relative",
            width: "100vw",
            height: "80vh",
          }}
        >
          <Fade
            {...Slideshowsettings}
            transitionDuration={1500}
            duration={1500}
            arrow={true}
            easing="ease"
          >
            {slideShowImages.map((item, index) => {
              return (
                <div class="row m-0 w-100">
                  <div
                    class={"p-0 align-items-center d-flex "}
                    key={index}
                    style={{
                      width: "100%",
                      height: "90vh",
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundImage: `url(` + item.url + `)`,
                    }}
                  >
                    <div class="row m-0 w-100 pt-4">
                      <div class="col-lg-12 allcentered">
                        <p
                          class="m-0 p-0 text-white primaryfontbold"
                          style={{ fontSize: "40px", lineHeight: "40px" }}
                        >
                          {item.caption1}
                        </p>
                      </div>
                      <div class="col-lg-12 allcentered">
                        <p
                          class="m-0 p-0 text-white primaryfontbold font-sm-80"
                          style={{ fontSize: "90px", fontFamily: "dinbold" }}
                        >
                          {item.caption2}
                        </p>
                      </div>
                      <div
                        class="col-lg-12 allcentered"
                        style={{ fontSize: "20px" }}
                      >
                        <p class="m-0 p-0 text-white primaryfontbold">
                          {item.caption3}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Fade>
        </div>
      </div>
      <div
        class="col-lg-12 p-0 allcentered pt-5 pb-5 mt-3"
        data-aos="fade-down"
      >
        <div class="row m-0 w-100">
          <div class="col-lg-12 d-flex flex-column allcentered">
            <p
              class="m-0 p-0 primaryfontbold text-primary text-center"
              style={{ fontSize: "30px", lineHeight: "32px" }}
            >
              جائزة الجمهور هي
            </p>
            <p
              class="m-0 p-0 primaryfontbold mb-5 text-secondary text-center d-flex d-sm-none"
              style={{ fontSize: "70px" }}
            >
              جائزة للجميع
            </p>
            <p
              class="m-0 p-0 primaryfontbold mb-5 text-secondary text-center d-none d-sm-flex"
              style={{ fontSize: "70px", lineHeight: "70px" }}
            >
              جائزة
              <br /> للجميع
            </p>
            <p
              class="m-0 p-0 text-black mb-3 text-center primaryfontregular"
              style={{ fontSize: "20px" }}
            >
              هي جائزة مالية قيّمة لمسابقة ثقافية وطنية، وستكون نموذجًا متطورًا
              وحديثًا
            </p>
            <p
              class="m-0 p-0 text-black text-center primaryfontregular"
              style={{ fontSize: "20px" }}
            >
              تدور أسئلة المسابقة حول مفاهيم ومستهدفات رؤية المملكة 2030، وتوجّه
              للمواطن والمقيم
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 p-0 allcentered pt-3 pb-3" data-aos="fade-down">
        <div class="row m-0 w-100 allcentered d-flex pl-5 pr-5">
          <div class="col-lg-12 d-flex justify-content-center mb-2">
            <p
              class="m-0 p-0 primaryfontbold"
              style={{ color: "#4c9c2e", fontSize: "19px" }}
            >
              فكرة ومبادرة ورعاية
            </p>
          </div>
          <div class="col-lg-12 d-flex justify-content-center mb-2">
            <p
              class="m-0 p-0 primaryfontbold"
              style={{
                color: "#005640",
                fontSize: "29px",
                textAlign: "right",
              }}
            >
              بندر مقبل اليابسي
            </p>
          </div>
          <div
            class="col-lg-12 d-flex justify-content-center justify-content-sm-center mb-3 mb-sm-4"
            onClick={() => {
              window.open("https://twitter.com/bandr_alyabsi?s=08", "_blank");
            }}
          >
            <AiOutlineTwitter
              class="text-primary ml-2 text-primaryhover"
              size={45}
            />
          </div>
          <div class="col-lg-12 d-flex justify-content-center mb-2">
            <img src={avatar} class={`${generalstyles.sponsor}` + " p-md-0 "} />
          </div>
        </div>
      </div>

      {/* {FetchWebsiteinfoQuery?.data?.data?.lastwinner != null && (
                <div class="col-lg-12 p-0 allcentered pt-5 pb-5" data-aos="fade-down" style={{ background: '#005640' }}>
                    <p class="m-0 p-0 text-white" style={{ fontSize: '30px' }}>
                        الفائز السابق:
                    </p>
                    <p class="m-0 p-0 text-secondary ml-2 mr-2 primaryfontbold text-white" style={{ fontSize: '30px' }}>
                        {FetchWebsiteinfoQuery?.data?.data?.lastwinner?.winnerinfo?.name}
                    </p>
                </div>
            )} */}
      {FetchWebsiteinfoQuery?.data?.data?.currentquestion != null && (
        <div class="col-lg-12 p-0 allcentered pt-5 pb-5" data-aos="fade-down">
          <div class="row m-0 w-100">
            <div class="col-lg-12 p-0 allcentered">
              <p
                class="m-0 p-0 text-primary primaryfontbold"
                style={{ fontSize: "30px" }}
              >
                كل 30 يوم جائزة
              </p>
            </div>
            <div class="col-lg-12 p-0 allcentered">
              <p
                class="m-0 p-0 text-secondary ml-2 mr-2 primaryfontmedium text-secondary"
                style={{ fontSize: "30px" }}
              >
                باقى على السحب ({remaining.days}) يوم
              </p>
            </div>
          </div>
        </div>
      )}
      <div class="col-lg-12 d-flex align-items-center justify-content-center mt-3 pb-5">
        <Follow
          username={"bandr_alyabsi"}
          options={{ lang: "ar", size: "large", showCount: "false" }}
        />
      </div>
      <div
        class="col-lg-12 p-0 allcentered pt-5 pb-5"
        data-aos="fade-down"
        style={{ background: "#005640" }}
      >
        <div class="row m-0 w-100 d-flex flex-column justify-content-center align-items-center pl-sm-3 pr-sm-3">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch mb-4">
            <div class={`${generalstyles.card}` + " w-100 pt-5 pb-4"}>
              <div class="row m-0 w-100 d-flex flex-column allcentered">
                <p
                  class="text-primary primaryfontbold mb-0"
                  style={{ fontSize: "22px", lineHeight: "22px" }}
                >
                  المشارك الفائز
                </p>
                <p
                  class="text-secondary robotomedium mb-2"
                  style={{ fontSize: "60px" }}
                >
                  50,000
                </p>
                <p
                  class="text-primary primaryfontbold mb-4"
                  style={{ fontSize: "20px" }}
                >
                  ريال سعودي
                </p>
                <button
                  class={`${generalstyles.subscribebtn}` + " allcentered "}
                  onClick={() => {
                    window.open(
                      window.location.origin + "/subscribe",
                      "_blank"
                    );
                    // history.push('/subscribe');
                  }}
                >
                  <p class="m-0 p-0 mt-n2">اشترك الآن</p>
                </button>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex align-items-stretch">
            <div class={`${generalstyles.card}` + " w-100 pt-5 pb-4"}>
              <div class="row m-0 w-100 d-flex flex-column allcentered">
                <i
                  class="fas fa-hands-helping text-secondary"
                  style={{ fontSize: "45px" }}
                ></i>
                <p
                  class="text-primary primaryfontbold mb-0 text-center"
                  style={{ fontSize: "22px", lineHeight: "30px" }}
                >
                  دعم جود الإسكان للأسر <br />
                  الأشد حاجة
                </p>
                <p
                  class="text-secondary robotomedium mb-2"
                  style={{ fontSize: "60px" }}
                >
                  50,000
                </p>
                <p
                  class="text-primary primaryfontbold mb-4"
                  style={{ fontSize: "20px" }}
                >
                  ريال سعودي
                </p>
                <button
                  class={`${generalstyles.subscribebtn}` + " allcentered "}
                  onClick={(e) => {
                    window.open("https://www.joodeskan.sa/", "_blank");
                  }}
                >
                  <p class="m-0 p-0 mt-n2">جود الإسكان</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 p-0 allcentered pt-4 pb-4" data-aos="fade-down">
        {/* <div class="row m-0 w-100 allcentered d-flex flex-column">
                    <p class="m-0 p-0 text-primary primaryfontbold mb-3" style={{ fontSize: '28px' }}>
                        فيديو عن جائزة الجمهور
                    </p>
                    <div class="d-flex d-md-none">
                        <ReactPlayer url={FetchWebsiteinfoQuery?.data?.data?.websiteinfo?.youtubelink} width="1200px" height="550px" />
                    </div>
                    <div class="d-none d-md-flex d-sm-none">
                        <ReactPlayer url={FetchWebsiteinfoQuery?.data?.data?.websiteinfo?.youtubelink} width="650px" height="450px" />
                    </div>
                    <div class="d-none d-sm-flex">
                        <ReactPlayer url={FetchWebsiteinfoQuery?.data?.data?.websiteinfo?.youtubelink} width="90vw" height="250px" />
                    </div>
                </div> */}
      </div>
      <div
        class="col-lg-12 allcentered "
        style={{
          backgroundImage: `url(${slider})`,
          height: 400,
          backgroundSize: "cover",
        }}
      >
        <div class="row m-0 w-100 allcentered flex-column">
          <p
            class="m-0 p-0 text-white primaryfontbold"
            style={{ fontSize: "30px" }}
          >
            الجائزة الكبرى
          </p>
          <p
            class="m-0 p-0 text-white robotomedium font-sm-70"
            style={{ fontSize: "100px" }}
          >
            1,000,000
          </p>
          <p
            class="m-0 p-0 text-white primaryfontbold"
            style={{ fontSize: "20px" }}
          >
            ريال سعودي
          </p>
        </div>
      </div>
      <div class="col-lg-12 allcentered mt-5 p-0">
        <div class="row m-0 w-100 allcentered flex-column">
          <p
            class="m-0 p-0 primaryfontbold text-center"
            style={{ fontSize: "20px" }}
          >
            كل شهر 100,000 ريال من 2022/09/01م
          </p>
          <div class="separator mt-4 mb-4 w-sm-90" />
          <div class="col-lg-12 allcentered">
            <p
              class="m-0 p-0 primaryfontbold text-center"
              style={{ fontSize: "20px" }}
            >
              والسحب على 1,000,000 ريال في 2023/09/01م
            </p>
          </div>
          <div class=" separator mt-4 mb-4 w-sm-90" />
          {/* <h4 class="mt-3 mb-3 text-center primaryfontbold">
            الأسئلة والسحب عبر حساب
          </h4> */}
          {/* <h3 class="mb-3 text-center primaryfontbold">فايز المالكي في سناب شات</h3> */}
          {/* <FaSnapchat
                        class="text-primary mt-4 mb-4 text-primaryhover"
                        size={55}
                        onClick={() => {
                            window.open('https://snapchat.com/add/fayezmalki', '_blank');
                        }}
                    /> */}
          <i
            class="fas fa-arrow-down text-secondary mb-5 mt-1"
            style={{ fontSize: "50px" }}
          ></i>
          <div class="row m-0 w-100 allcentered flex-row flex-sm-column">
            <div class="d-flex flex-column allcentered mb-sm-4">
              <FaUserCheck size={40} class="text-secondary mb-1" />
              <p class="primaryfontbold" style={{ fontSize: "17px" }}>
                تابع الحساب
              </p>
            </div>
            <div class="col-lg-12 d-flex align-items-center justify-content-center mt-3 pb-5">
            <Follow
              username={"bandr_alyabsi"}
              options={{ lang: "ar", size: "large", showCount: "false" }}
            />
            </div>
            {/* <div class="d-flex flex-column allcentered mb-sm-4">
              <FaBell size={38} class="text-secondary mb-1" />
              <p class="primaryfontbold" style={{ fontSize: "17px" }}>
                فعل التنبيهات
              </p>
            </div> */}
            <div class="d-flex flex-column allcentered">
              {/* <BsCheck2Square size={38} class="text-secondary" /> */}
              <i
                class="far fa-check-square text-secondary mb-1"
                style={{ fontSize: "38px" }}
              ></i>
              <p class="primaryfontbold" style={{ fontSize: "17px" }}>
                شارك وسجل إجابتك
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-12 p-0 allcentered pt-5 pb-5 mt-1"
        data-aos="fade-down"
      >
        <div class="row m-0 w-100 allcentered d-flex flex-column">
          <button
            class={`${generalstyles.subscribebtn}` + " allcentered mb-5 "}
            onClick={() => {
              window.open(window.location.origin + "/subscribe", "_blank");
            }}
          >
            <p class="m-0 p-0 mt-n2">اشترك الآن</p>
          </button>
          <p
            class="m-0 p-0 primaryfontbold"
            style={{ color: "#4c9c2e", fontSize: "19px" }}
          >
            فكرة ومبادرة ورعاية
          </p>
          <p
            class="m-0 p-0 primaryfontbold"
            style={{
              color: "#005640",
              fontSize: "29px",
            }}
          >
            بندر مقبل اليابسي
          </p>
          <div class="d-flex flex-row allcentered mt-3">
            <AiOutlineTwitter
              class="text-primary ml-2 text-primaryhover"
              size={45}
              onClick={() => {
                window.open("https://twitter.com/bandr_alyabsi?s=08", "_blank");
              }}
            />
            <FaYoutube
              class="text-primary mr-2 text-primaryhover"
              size={45}
              onClick={() => {
                window.open(
                  "https://youtube.com/channel/UCC02gRLKolHgF_ckbV329eA",
                  "_blank"
                );
              }}
            />
          </div>
        </div>
      </div>
      <div
        class="col-lg-9 col-sm-10 pl-sm-0 pr-sm-0 m-auto bg-primary p-4 flex-column allcentered"
        style={{
          borderRadius: "40px 40px 0 0",
          boxShadow: "0 0 7px rgb(57, 57, 57)",
        }}
      >
        <p class="m-0 p-0 text-white primaryfontbold">جائزة الجمهور</p>
        <p class="m-0 p-0 text-white primaryfontmedium">جميع الحقوق محفوظة</p>
      </div>
    </div>
  );
};
export default Home;
