import axios from 'axios';
import { serverbaselink } from '../Env_Variables';

const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};
axios.defaults.withCredentials = true;

export const AddQuestion_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/admin/quizstore',
        // headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FetchQuestions_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/admin/FetchQuestions',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const FetchQuestionInfo_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/admin/FetchQuestionInfo',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const FetchQuestionWinner_API = (axiosdata) => {
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/admin/FetchQuestionWinner',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
export const CRUDAnswers_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/admin/CRUDAnswers',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AssignWinner_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/admin/AssignWinner',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Websitesettings_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/admin/Websitesettings',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchAuthorization_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/surfer/FetchAuthorization',
        headers: axiosheaders,
        params: axiosdata,
        withCredentials: true,
    });
    return axiosfetch;
};
export const FetchWebsitesettings_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/surfer/FetchWebsitesettings',
        headers: axiosheaders,
        params: axiosdata,
        withCredentials: true,
    });
    return axiosfetch;
};

export const AdminLogout_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/adminlogout',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const AdminLoginMutation_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/adminlogin',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const SubmitAnswer_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselink + '/surfer/submitanswer',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const FetchWebsiteinfo_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    const axiosfetch = axios({
        method: 'get',
        url: serverbaselink + '/surfer/FetchWebsiteinfo',
        headers: axiosheaders,
        params: axiosdata,
    });
    return axiosfetch;
};
