import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import { AddQuestion_API, FetchQuestionInfo_API, CRUDAnswers_API, FetchQuestions_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { serverbaselink } from '../../../Env_Variables';
const AddQuestion = () => {
    let history = useHistory();
    const { typeparams, idparams } = useParams();
    const [fromdate, setfromdate] = useState('all');
    const [todate, settodate] = useState('all');
    const [questionpayload, setquestionpayload] = useState({
        functype: typeparams,
        isquestionshownaslastwinner: 0,
        status: 0,
        dayscounting: 30,
        countsstartfromdate: '',
        question: '',
        qnum: '',
        answers: [],
    });
    const FetchQuestionInfoQuery = useQuery(['FetchQuestionInfo_API' + idparams], () => FetchQuestionInfo_API({ questionid: idparams }), {
        keepPreviousData: true,
        staleTime: Infinity,
        enabled: typeparams == 'edit' ? true : false,
    });
    const FetchQuestionsQuery = useQuery(['FetchQuestions_API'], () => FetchQuestions_API(), { keepPreviousData: true, staleTime: Infinity });

    const AddQuestionMutation = useMutation('AddQuestion_API', {
        mutationFn: AddQuestion_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                FetchQuestionsQuery.refetch();
                if (variables.functype == 'add') {
                    history.push('/dashboard/question/edit/' + data.data.questionid);
                } else if (variables.functype == 'edit') {
                    FetchQuestionInfoQuery.refetch();
                } else if (variables.functype == 'delete') {
                    history.push('/dashboard');
                }

                NotificationManager.success('', 'تم تحديث السؤال');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const CRUDAnswersMutation = useMutation('CRUDAnswers_API', {
        mutationFn: CRUDAnswers_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                FetchQuestionInfoQuery.refetch();
                // history.push('/dashboard/institute/orders/showorder/' + data.data.orderid);
                NotificationManager.success('', 'تم تحديث الاسئلة');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    useEffect(() => {
        if (FetchQuestionInfoQuery.isSuccess) {
            var tempquest = FetchQuestionInfoQuery.data.data.question;
            tempquest.functype = typeparams;
            tempquest.questionid = FetchQuestionInfoQuery.data.data.question.id;
            setquestionpayload(tempquest);
        }
    }, [FetchQuestionInfoQuery.isSuccess, FetchQuestionInfoQuery.data]);
    return (
        <div class="row m-0 w-100">
            <div class="col-lg-12 d-flex justify-content-start">
                <div class="row m-0 w-100 mt-3">
                    <div class="col-lg-12 d-flex align-items-center justify-content-start p-sm-0">
                        <i class="h-100 d-flex align-items-center justify-content-center pt-2">
                            <FiArrowRight
                                size={25}
                                class="ml-3 cursor-pointer"
                                onClick={() => {
                                    history.push('/dashboard');
                                }}
                            />
                        </i>
                        <p class="m-0 p-0 d-flex align-items-center text-secondary primaryfontmedium" style={{ color: '#263a5b', fontSize: '30px' }}>
                            الاسئلة
                        </p>
                    </div>
                    {typeparams == 'edit' && (
                        <div class="col-lg-12 row justify-content-end">
                            {/* <div class="row w-100 justify-content-end mb-3">
                                <div class="col-lg-4">
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium">من</p>
                                    </div>
                                    <input
                                        class={`${generalstyles.form_control2}`}
                                        value={fromdate}
                                        type="date"
                                        onChange={(event) => {
                                            if (event.target.value.length == 0) {
                                                setfromdate('all');
                                            } else {
                                                setfromdate(event.target.value);
                                            }
                                        }}
                                    />
                                </div>
                                <div class="col-lg-4">
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium">الي</p>
                                    </div>
                                    <input
                                        class={`${generalstyles.form_control2}`}
                                        value={todate}
                                        type="date"
                                        onChange={(event) => {
                                            if (event.target.value.length == 0) {
                                                settodate('all');
                                            } else {
                                                settodate(event.target.value);
                                            }
                                        }}
                                    />
                                </div>
                            </div> */}
                            <div class="row w-100 justify-content-end  justify-content-sm-center mb-3 mt-sm-3">
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_primary}` + ' text-center justify-content-center ml-2 mb-sm-3 primaryfontmedium '}
                                    disabled={AddQuestionMutation.disabled}
                                    onClick={() => {
                                        window.open(window.location.origin + '/winner/' + idparams + '/' + fromdate + '/' + todate, '_blank');
                                    }}
                                    style={{ width: '150px' }}
                                >
                                    السحب العشوائى
                                </button>
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_success}` + ' text-center justify-content-center primaryfontmedium mb-sm-3 '}
                                    disabled={AddQuestionMutation.disabled}
                                    onClick={() => {
                                        window.open(serverbaselink + '/download/' + idparams + '/' + fromdate + '/' + todate, '_blank');
                                    }}
                                    style={{ width: '150px' }}
                                >
                                    الاجبات الصحيحة (Excel)
                                </button>
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_danger}` + ' text-center justify-content-center mr-2 ml-2 primaryfontmedium '}
                                    disabled={AddQuestionMutation.disabled}
                                    onClick={() => {
                                        window.open(serverbaselink + '/download/failed/' + idparams + '/' + fromdate + '/' + todate, '_blank');
                                    }}
                                    style={{ width: '150px' }}
                                >
                                    الاجبات الخاطئة (Excel)
                                </button>
                            </div>
                        </div>
                    )}
                    {FetchQuestionInfoQuery.isFetching && (
                        <div class="w-100 text-center">
                            <CircularProgress color={'black'} width="40px" height="40px" />
                        </div>
                    )}

                    <div class="col-lg-12 d-flex justify-content-start mt-4 pt-4 pb-4" style={{ border: '1px solid #eee' }}>
                        <div class="row m-0 w-100">
                            <div class="col-lg-12 d-flex flex-column justify-content-start mb-4 p-sm-0">
                                <div class="row m-0 w-100">
                                    <div class="col-lg-12 d-flex flex-column justify-content-start mb-3">
                                        <div class={`${generalstyles.form_control2}` + ' w-100 '}>
                                            <select
                                                class={`${generalstyles.form_control2}` + ' primaryfontmedium '}
                                                style={{ border: 'none', background: 'transparent' }}
                                                value={questionpayload.status}
                                                onChange={(event) => {
                                                    setquestionpayload({ ...questionpayload, status: event.target.value });
                                                }}
                                            >
                                                <option value={1}>نشط</option>
                                                <option value={0}>غير مفغل</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start mb-3">
                                        <div class={`${generalstyles.form_control2}` + ' w-100 primaryfontmedium '}>
                                            <select
                                                class={`${generalstyles.form_control2}`}
                                                style={{ border: 'none', background: 'transparent' }}
                                                value={questionpayload.isquestionshownaslastwinner}
                                                onChange={(event) => {
                                                    setquestionpayload({ ...questionpayload, isquestionshownaslastwinner: event.target.value });
                                                }}
                                            >
                                                <option value={1}>اظهار الفائز</option>
                                                <option value={0}>عدم اظهار الفائز</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium">تاريخ العد التنازلي</p>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <input
                                            type="date"
                                            class={`${generalstyles.form_control2}`}
                                            value={questionpayload.countsstartfromdate}
                                            onChange={(event) => {
                                                setquestionpayload({ ...questionpayload, countsstartfromdate: event.target.value });
                                            }}
                                        />
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium"> عدد الايام</p>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <input
                                            type="number"
                                            class={`${generalstyles.form_control2}`}
                                            value={questionpayload.dayscounting}
                                            onChange={(event) => {
                                                setquestionpayload({ ...questionpayload, dayscounting: event.target.value });
                                            }}
                                        />
                                    </div>
                                    {typeparams == 'edit' && (
                                        <>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <p class="d-flex justify-content-start mb-2 primaryfontmedium"> رقم السؤال</p>
                                            </div>
                                            <div class="col-lg-12 d-flex flex-column justify-content-start">
                                                <input
                                                    type="number"
                                                    class={`${generalstyles.form_control2}`}
                                                    value={questionpayload.qnum}
                                                    onChange={(event) => {
                                                        setquestionpayload({ ...questionpayload, qnum: event.target.value });
                                                    }}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium">السؤال</p>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <input
                                            class={`${generalstyles.form_control2}`}
                                            value={questionpayload.question}
                                            onChange={(event) => {
                                                setquestionpayload({ ...questionpayload, question: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 d-flex flex-column justify-content-start p-sm-0">
                                <div class="row m-0 w-100">
                                    <div class="col-lg-12 d-flex flex-row justify-content-start align-items-center mb-3">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium">الإجابات </p>
                                        <div
                                            style={{ width: '5vh', height: '5vh', borderRadius: 100 }}
                                            class="ml-2 mr-2 allcentered bg-primary bg-primaryhover"
                                            onClick={() => {
                                                var tempquestionpayload = { ...questionpayload };
                                                tempquestionpayload.answers.push({
                                                    answertext: '',
                                                    iscorrect: 0,
                                                    isnew: 1,
                                                });
                                                setquestionpayload({ ...tempquestionpayload });
                                            }}
                                        >
                                            <i class="fa fa-plus text-white"></i>
                                        </div>
                                    </div>
                                    {questionpayload?.answers?.map(function (item, index) {
                                        return (
                                            <div class="col-lg-6 row d-flex flex-row justify-content-start mb-3">
                                                <input
                                                    class={`${generalstyles.form_control2}` + ' primaryfontmedium '}
                                                    style={{ width: '80%' }}
                                                    value={item.answertext}
                                                    onChange={(event) => {
                                                        var tempquestionpayload = { ...questionpayload };
                                                        tempquestionpayload.answers[index].answertext = event.target.value;
                                                        setquestionpayload({ ...tempquestionpayload });
                                                    }}
                                                />
                                                <div class="d-flex align-items-center">
                                                    <Checkbox
                                                        label="label"
                                                        size="small"
                                                        defaultChecked={item.iscorrect == 1 ? true : false}
                                                        defaultValue={item.iscorrect == 1 ? true : false}
                                                        sx={{
                                                            color: 'var(--primary)',
                                                            '&.Mui-checked': {
                                                                color: 'var(--secondary)',
                                                            },
                                                        }}
                                                        onChange={() => {
                                                            var tempquestionpayload = { ...questionpayload };
                                                            if (tempquestionpayload.answers[index].iscorrect == 1) {
                                                                tempquestionpayload.answers[index].iscorrect = 0;
                                                            } else {
                                                                tempquestionpayload.answers[index].iscorrect = 1;
                                                            }
                                                            setquestionpayload({ ...tempquestionpayload });
                                                        }}
                                                    />
                                                    <p class="cursor-pointer text-primaryhover m-0 p-0" style={{ color: '#999999' }}>
                                                        إجابة صحيحة
                                                    </p>
                                                </div>
                                                {typeparams == 'edit' && (
                                                    <>
                                                        <div class="col-lg-12 d-flex align-items-center justify-content-start mt-4">
                                                            <button
                                                                class={`${generalstyles.btn} ${generalstyles.btn_success}`}
                                                                disabled={CRUDAnswersMutation.disabled}
                                                                onClick={() => {
                                                                    if (item.answertext.length != 0) {
                                                                        var functype = '';
                                                                        if (item.isnew == 1) {
                                                                            functype = 'add';
                                                                        } else {
                                                                            functype = 'edit';
                                                                        }
                                                                        CRUDAnswersMutation.mutate({
                                                                            functype: functype,
                                                                            answerid: item.id,
                                                                            answertext: item.answertext,
                                                                            iscorrect: item.iscorrect,
                                                                            questionid: idparams,
                                                                        });
                                                                    } else {
                                                                        NotificationManager.warning('', 'من فضلك اكمل باقى البيانات');
                                                                    }
                                                                }}
                                                            >
                                                                {CRUDAnswersMutation.isLoading && <CircularProgress color={'white'} width="20px" height="20px" />}
                                                                {!CRUDAnswersMutation.isLoading && <span>{item.isnew == 1 ? 'اضافة الاجابة' : 'تعديل الاجابة'}</span>}
                                                            </button>
                                                            <button
                                                                class={`${generalstyles.btn} ${generalstyles.btn_danger}` + ' ml-2 mr-2 '}
                                                                onClick={() => {
                                                                    CRUDAnswersMutation.mutate({
                                                                        functype: 'delete',
                                                                        answerid: item.id,

                                                                        questionid: idparams,
                                                                    });
                                                                }}
                                                                disabled={CRUDAnswersMutation.isLoading}
                                                            >
                                                                {CRUDAnswersMutation.isLoading && <CircularProgress color={'white'} width="20px" height="20px" />}
                                                                {!CRUDAnswersMutation.isLoading && <span> حذف الاجابة</span>}
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            <div class="col-lg-12 d-flex align-items-center justify-content-center mt-4">
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_success}` + ' text-center justify-content-center'}
                                    disabled={AddQuestionMutation.disabled}
                                    onClick={() => {
                                        if (typeparams == 'add') {
                                            if (questionpayload.answers.length != 0 && questionpayload.question.length != 0) {
                                                AddQuestionMutation.mutate(questionpayload);
                                            } else {
                                                NotificationManager.warning('', 'من فضلك اكمل باقى البيانات');
                                            }
                                        } else if (typeparams == 'edit') {
                                            if (questionpayload.question.length != 0) {
                                                AddQuestionMutation.mutate(questionpayload);
                                            } else {
                                                NotificationManager.warning('', 'من فضلك اكمل باقى البيانات');
                                            }
                                        }
                                    }}
                                >
                                    {AddQuestionMutation.isLoading && <CircularProgress color={'white'} width="20px" height="20px" />}
                                    {!AddQuestionMutation.isLoading && <span>حفظ</span>}
                                </button>
                                {typeparams == 'edit' && (
                                    <button
                                        class={`${generalstyles.btn} ${generalstyles.btn_danger}` + ' ml-2 mr-2 '}
                                        onClick={() => {
                                            if (window.confirm('This will delete all the data related to this question')) {
                                                AddQuestionMutation.mutate({ functype: 'delete', questionid: idparams });
                                            }
                                        }}
                                        disabled={AddQuestionMutation.isLoading}
                                    >
                                        حذف السؤال
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AddQuestion;
