import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import Group69 from '../../Generalfiles/images/Group69.png';
import AOS from 'aos';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import logo from '../../Generalfiles/images/logo.svg';
import visionlogo from '../../Generalfiles/images/visionlogo.svg';
import { BsArrowRightShort } from 'react-icons/bs';
import { useHistory, useParams } from 'react-router-dom';
import { FetchAuthorization_API, FetchQuestionWinner_API, AssignWinner_API, FetchQuestions_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { NotificationManager } from 'react-notifications';
import 'aos/dist/aos.css';
AOS.init();
const Winner = () => {
    let history = useHistory();
    const { questionidparams, fromdateparams, todateparams } = useParams();
    const [randomdone, setrandomdone] = useState(false);
    const [winnername, setwinnername] = useState('');
    const [showbutton, setshowbutton] = useState(true);
    const FetchQuestionWinnerQuery = useQuery(
        ['FetchQuestionWinner_API' + questionidparams + fromdateparams + todateparams],
        () => FetchQuestionWinner_API({ questionid: questionidparams, fromdate: fromdateparams, todate: todateparams }),
        {
            keepPreviousData: true,
            staleTime: Infinity,
        },
    );
    const FetchQuestionsQuery = useQuery(['FetchQuestions_API'], () => FetchQuestions_API(), { keepPreviousData: true, staleTime: Infinity });

    const AssignWinnerMutation = useMutation('AssignWinner_API', {
        mutationFn: AssignWinner_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                FetchQuestionsQuery.refetch();

                NotificationManager.success('', 'Question Answers Updated');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });

    const randomnamesfor30seconds = () => {
        if (FetchQuestionWinnerQuery.isSuccess) {
            setshowbutton(false);
            const winnerchosoeinterval = setInterval(function () {
                var item = FetchQuestionWinnerQuery.data.data.winners[Math.floor(Math.random() * FetchQuestionWinnerQuery.data.data.winners.length)];
                setwinnername(item.name);
            }, 80);

            setTimeout(function () {
                AssignWinnerMutation.mutate({
                    useranswerid: FetchQuestionWinnerQuery.data.data.randomwinner.UserAnswerid,
                    questionid: questionidparams,
                });
                setrandomdone(true);
                setwinnername(FetchQuestionWinnerQuery.data.data.randomwinner.name);
                clearInterval(winnerchosoeinterval);
                // setshowbutton(true);
            }, 30000);
        }
    };
    return (
        <div class="row m-0 w-100" style={{ height: '100vh', objectFit: 'contain' }}>
            {FetchQuestionWinnerQuery.isFetching && (
                <div class="w-100 text-center allcentered" style={{ height: '100vh' }}>
                    <CircularProgress color={'#ccc'} width="60px" height="60px" />
                </div>
            )}
            {FetchQuestionWinnerQuery.isSuccess && (
                <div class="col-lg-12 p-0 allcentered">
                    <div class="row m-0 w-100 allcentered">
                        <img src={Group69} style={{ objectFit: 'cover', height: '100%', width: '100%', position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }} />
                        <div
                            class={generalstyles.lotterybackbtn + ' allcentered '}
                            onClick={() => {
                                history.push('/dashboard');
                            }}
                        >
                            <BsArrowRightShort size={30} />
                        </div>
                        <div class="col-lg-12 d-flex flex-column justify-content-center" style={{ zIndex: 1000 }}>
                            <p style={{ color: '#ccc', fontSize: '45px' }} class="primaryfontmedium text-center p-0 m-0 font-sm-30">
                                السحب العشوائى
                            </p>
                            {randomdone && (
                                <p style={{ fontSize: '60px', color: '#93d500' }} class="primaryfontmedium text-center font-sm-40 m-0">
                                    الفائز
                                    {/* <span style={{ fontSize: '90px' }} class="font-sm-80">
                                    50
                                </span> */}
                                    {/* الف ريال سعودى */}
                                </p>
                            )}
                            <p style={{ color: '#ccc', fontSize: '60px' }} class="primaryfontmedium text-center mb-5">
                                {winnername}
                            </p>
                            {FetchQuestionWinnerQuery.data.data.winners.length == 0 && (
                                <p style={{ color: '#ccc', fontSize: '40px' }} class="primaryfontmedium text-center mb-5">
                                    لا يوجد فائز
                                </p>
                            )}
                            {FetchQuestionWinnerQuery.data.data.winners.length != 0 && showbutton && (
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_winner}` + ' ml-auto mr-auto '}
                                    onClick={() => {
                                        randomnamesfor30seconds();
                                    }}
                                >
                                    إختيار الفائز
                                </button>
                            )}
                        </div>
                        <img src={logo} class={generalstyles.logowinner} />
                        <img src={visionlogo} class={generalstyles.visionlogowinner} />
                    </div>
                </div>
            )}
        </div>
    );
};
export default Winner;
