import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import { useHistory, useParams } from 'react-router-dom';
import { AiOutlineFileExcel } from 'react-icons/ai';
import { FetchQuestions_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
const HomeDashboard = () => {
    let history = useHistory();
    const FetchQuestionsQuery = useQuery(['FetchQuestions_API'], () => FetchQuestions_API(), { keepPreviousData: true, staleTime: Infinity });

    return (
        <div class="row m-0 w-100 ">
            <div class="col-lg-9 col-md-9 col-sm-7 d-flex justify-content-start">
                <p class="m-0 p-0 primaryfontmedium" style={{ color: '#263a5b', fontSize: '30px' }}>
                    الاسئلة
                </p>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-5 d-flex justify-content-end">
                <button
                    class={`${generalstyles.btn} ${generalstyles.btn_success}`}
                    style={{ height: '40px' }}
                    onClick={() => {
                        history.push('/dashboard/question/add/all');
                    }}
                >
                    إضافة سؤال
                </button>
            </div>
            <div class="col-lg-12">
                <hr />
            </div>
            <div class="col-lg-12 d-flex justify-content-end justify-content-md-center align-items-center mb-3">
                <p class="m-0 p-0 ml-2 mr-2" style={{ color: '#8A98AC' }}>
                    ابحث
                </p>
                <input style={{ width: '200px', height: '35px', border: '1px solid #8A98AC', borderRadius: '7px' }} />
            </div>
            <div class="col-lg-12">
                {!FetchQuestionsQuery.isSuccess && (
                    <div class="w-100 allcentered" style={{ height: 300 }}>
                        <CircularProgress color={'#ccc'} width="40px" height="40px" />
                    </div>
                )}
                <div className={'table_responsive'}>
                    {FetchQuestionsQuery.isSuccess && (
                        <table className={'table table_hover table_bordered'}>
                            <thead className="">
                                <th>الرقم</th>
                                <th>السؤال</th>
                                <th>التاريخ</th>
                                <th>عدد المشاركين</th>
                                {/* <th>حالة ظهور الفائز</th> */}
                                <th>حالة النشر</th>
                                <th> الفائز</th>
                                <th> السحب العشوائى</th>
                                <th>المزيد</th>
                            </thead>
                            <tbody>
                                {FetchQuestionsQuery.data.data.questions.map(function (item, index) {
                                    return (
                                        <tr>
                                            <td>{item.qnum}</td>
                                            <td>{item.question}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.answeredusers_count}</td>
                                            {/* <td>
                                                {item.isquestionshownaslastwinner == 1 && (
                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_success}`} style={{ width: '60px', height: '35px' }}>
                                                        مفعلة
                                                    </button>
                                                )}
                                                {item.isquestionshownaslastwinner == 0 && (
                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_danger}`} style={{ width: '80px', height: '35px' }}>
                                                        غير مفعلة
                                                    </button>
                                                )}
                                            </td> */}
                                            <td>
                                                {item.status == 1 && (
                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_success}`} style={{ width: '60px', height: '35px' }}>
                                                        مفعلة
                                                    </button>
                                                )}
                                                {item.status == 0 && (
                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_danger}`} style={{ width: '80px', height: '35px' }}>
                                                        غير مفعلة
                                                    </button>
                                                )}
                                            </td>
                                            <td style={{ minWidth: '220px' }}>
                                                {item.winnerinfo != null && (
                                                    <div
                                                        class={`${generalstyles.btn} ${generalstyles.btn_success}` + ' pl-2 pr-2  primaryfontbold d-flex flex-column '}
                                                        style={{ paddingBottom: 5, width: '100%', height: '60px' }}
                                                    >
                                                        <div class={' pl-2 pr-2 primaryfontbold d-flex flex-column  '} style={{ paddingBottom: 5 }}>
                                                            <p class="m-0 pt-2">{item?.winnerinfo?.name}</p>
                                                            <p class="m-0 pt-2" style={{ direction: 'ltr' }}>
                                                                {item?.winnerinfo?.phone}
                                                            </p>
                                                        </div>
                                                    </div>
                                                )}
                                                {item.winnerinfo == null && (
                                                    <button class={`${generalstyles.btn} ${generalstyles.btn_danger}`} style={{ width: '80px', height: '35px' }}>
                                                        لا يوجد
                                                    </button>
                                                )}
                                            </td>
                                            <td
                                                onClick={() => {
                                                    window.open(window.location.origin + '/winner/' + item.id + '/all' + '/all', '_blank');
                                                }}
                                            >
                                                <button class={`${generalstyles.btn} ${generalstyles.btn_primary}` + ' mb-sm-2 '} style={{ width: '120px', height: '35px' }}>
                                                    السحب العشوائى
                                                </button>
                                            </td>
                                            <td
                                                onClick={() => {
                                                    history.push('/dashboard/question/edit/' + item.id);
                                                }}
                                            >
                                                <button class={`${generalstyles.btn} ${generalstyles.btn_info}` + ' mb-sm-2 '} style={{ width: '60px', height: '35px' }}>
                                                    تفاصيل
                                                </button>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </div>
    );
};
export default HomeDashboard;
