import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import { FiArrowRight } from 'react-icons/fi';
import { useHistory, useParams } from 'react-router-dom';
import { FetchWebsitesettings_API, Websitesettings_API } from '../../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
const Websitesettings = () => {
    let history = useHistory();
    const [websitesettings, setwebsitesettings] = useState({
        youtubelink: '',
    });
    const WebsitesettingsMutation = useMutation('Websitesettings_API', {
        mutationFn: Websitesettings_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                NotificationManager.success('', 'تم تحديث الويب سايت');
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    return (
        <div class="row m-0 w-100">
            <div class="col-lg-12 d-flex justify-content-start">
                <div class="row m-0 w-100 mt-3">
                    <div class="col-lg-12 d-flex flex-row justify-content-start">
                        <FiArrowRight
                            size={25}
                            class="ml-3 mt-3 cursor-pointer"
                            onClick={() => {
                                history.push('/dashboard');
                            }}
                        />
                        <p class="m-0 p-0 d-flex align-items-center text-secondary primaryfontmedium" style={{ color: '#263a5b', fontSize: '30px' }}>
                            الاعدادات
                        </p>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-start mt-4 pt-4 pb-4" style={{ border: '1px solid #eee' }}>
                        <div class="row m-0 w-100">
                            <div class="col-lg-7 d-flex flex-column justify-content-start mb-4">
                                <div class="row m-0 w-100">
                                    <div class="col-lg-12 d-flex flex-column justify-content-start">
                                        <p class="d-flex justify-content-start mb-2 primaryfontmedium"> رابط Youtube </p>
                                    </div>
                                    <div class="col-lg-12 d-flex flex-column justify-content-start primaryfontmedium">
                                        <input
                                            class={`${generalstyles.form_control2}`}
                                            value={websitesettings.youtubelink}
                                            onChange={(event) => {
                                                setwebsitesettings({ ...websitesettings, youtubelink: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 d-flex align-items-center justify-content-center mt-4">
                                <button
                                    class={`${generalstyles.btn} ${generalstyles.btn_success}`}
                                    onClick={() => {
                                        WebsitesettingsMutation.mutate(websitesettings);
                                    }}
                                >
                                    حفظ
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Websitesettings;
