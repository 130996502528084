import React, { useEffect, useState, useContext } from 'react';
import generalstyles from '../Generalfiles/CSS_GENERAL/general.module.css';
import hero from '../Generalfiles/images/main-bg.png';
import logo from '../Generalfiles/images/logo.svg';
import visionlogo from '../Generalfiles/images/visionlogo.svg';
import Checkbox from '@mui/material/Checkbox';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { AdminLoginMutation_API } from '../../API/API';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { serverbaselink } from '../../Env_Variables';
AOS.init();
const Login = () => {
    const [loginpayload, setloginpayload] = useState({
        email: '',
        password: '',
    });
    const LoginMutation = useMutation('AdminLoginMutation_API', {
        mutationFn: AdminLoginMutation_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                // history.push('/dashboard/institute/orders/showorder/' + data.data.orderid);
                // NotificationManager.success('', lang.orderisaddedsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    return (
        <div class="row m-0 w-100" style={{ backgroundImage: `url(${hero})`, backgroundSize: 'contain', height: '100vh' }}>
            <div class="col-lg-12 mt-3 p-0">
                <div class="row m-0 w-100">
                    <div class="col-lg-12 allcentered p-0" style={{ height: 100 }}>
                        <img src={logo} class={`${generalstyles.logo2}` + ' ml-3 '} />
                        <img src={visionlogo} class={`${generalstyles.visionlogo2}` + ' mr-4 '} />
                    </div>
                    <form class={' col-lg-12 pt-3 mt-4'} method="post" action={serverbaselink + '/surfer/adminlogin'} id="loginform">
                        <div class={`${generalstyles.formcontainer} ${generalstyles.loginformcontainer}`}>
                            <div class="row m-0 w-100 pt-5 pl-4 pr-4 pl-sm-2 pr-sm-2">
                                <div class="col-lg-12 d-flex justify-content-start mt-3">
                                    <p class="m-0 p-0 text-secondary primaryfontmedium" style={{ fontSize: '16px' }}>
                                        تسجيل دخول
                                    </p>
                                </div>
                                <div class="col-lg-12 mt-3 mb-3">
                                    <div class={generalstyles.form_control + ' pr-1 pl-2 '}>
                                        <input
                                            class={`${generalstyles.form_control} ${generalstyles.form_control_noshadow}` + ' pl-1 pr-1 primaryfontmedium '}
                                            placeholder={' البريد الالكتروني'}
                                            value={loginpayload.email}
                                            name="email"
                                            onChange={(event) => {
                                                setloginpayload({ ...loginpayload, email: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-3">
                                    <div class={generalstyles.form_control + ' pr-1 pl-2 '}>
                                        <input
                                            class={`${generalstyles.form_control} ${generalstyles.form_control_noshadow}` + ' pl-1 pr-1 primaryfontmedium '}
                                            placeholder={' كلمة السر'}
                                            type="password"
                                            name="password"
                                            value={loginpayload.password}
                                            onChange={(event) => {
                                                setloginpayload({ ...loginpayload, password: event.target.value });
                                            }}
                                        />
                                    </div>
                                </div>

                                <div class="col-lg-12 allcentered mt-5 pt-4 mb-4">
                                    <button
                                        class={`${generalstyles.formbtn}`}
                                        onClick={() => {
                                            if (loginpayload.email.length != 0 && loginpayload.password.length != 0) {
                                                // LoginMutation.mutate(loginpayload);
                                                document.getElementById('loginform').submit();
                                            } else {
                                                NotificationManager.warning('', 'من فضلك اكمل باقى البيانات');
                                            }
                                        }}
                                        disabled={LoginMutation.isLoading}
                                        type="button"
                                    >
                                        {!LoginMutation.isLoading && <span class="primaryfontmedium">تسجيل دخول</span>}
                                        {LoginMutation.isLoading && <CircularProgress color={'white'} width="20px" height="20px" />}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
export default Login;
