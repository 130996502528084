import React, { useEffect, useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import generalstyles from '../../Generalfiles/CSS_GENERAL/general.module.css';
import hero from '../../Generalfiles/images/main-bg.png';
import logo from '../../Generalfiles/images/logo.svg';
import visionlogo from '../../Generalfiles/images/visionlogo.svg';
import Checkbox from '@mui/material/Checkbox';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FetchWebsiteinfo_API, SubmitAnswer_API } from '../../../API/API';
import { NotificationManager } from 'react-notifications';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { useHistory, useParams } from 'react-router-dom';
import { FaRegCheckCircle, FaTwitter, F } from 'react-icons/fa';
import { Follow } from 'react-twitter-widgets';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
AOS.init();
const Subscribe = () => {
    let history = useHistory();
    const FetchWebsiteinfoQuery = useQuery(['FetchWebsiteinfo_API'], () => FetchWebsiteinfo_API(), { keepPreviousData: true, staleTime: Infinity });
    const [answerpayload, setanswerpayload] = useState({
        name: '',
        name2: '',
        phone: '',
        phone2: '',
        answerid: '',
        answertext: '',
        istermsaccepted: 0,
    });
    const [phonenumberAlreadyexists, setsetphonenumberAlreadyexists] = useState(false);
    const [currentphase, setcurrentphase] = useState('first');
    const SubmitAnswerMutation = useMutation('SubmitAnswer_API', {
        mutationFn: SubmitAnswer_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                setcurrentphase('third');
            } else {
                setsetphonenumberAlreadyexists(true);
                setcurrentphase('first');
            }
        },
    });
    function isNumber(n) {
        return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
    }

    const [isOpen, setIsOpen] = useState(false);
    return (
        <div class="row m-0 w-100 bg allcentered" style={{ backgroundImage: `url(${hero})`, backgroundSize: 'cover', height: '100vh' }}>
            {FetchWebsiteinfoQuery.isSuccess && (
                <div class="col-lg-12 allcentered p-0">
                    <div class="row m-0 w-100">
                        <div class="col-lg-12 allcentered p-0" style={{ height: 100 }}>
                            <img src={logo} class={`${generalstyles.logo2}` + ' ml-3 ml-sm-1 '} />
                            <img src={visionlogo} class={`${generalstyles.visionlogo2}` + ' mr-4 mr-sm-2 '} />
                        </div>
                        <form class={' col-lg-12 mt-3 mb-5 mt-sm-0'}>
                            <div class={`${generalstyles.formcontainer} ${generalstyles.subscribeformcontainer}`}>
                                <div class="row m-0 w-100 pt-5 pl-4 pr-4 pl-sm-3 pr-sm-3">
                                    <div class="col-lg-12 allcentered">
                                        <div
                                            style={{ width: '3vh', height: '3vh', background: 'var(--primary)', borderRadius: 100, cursor: 'pointer' }}
                                            onClick={() => {
                                                if (currentphase == 'second') {
                                                    setcurrentphase('first');
                                                }
                                            }}
                                        ></div>
                                        <div style={{ flex: 1, background: currentphase == 'second' || currentphase == 'third' ? 'var(--primary)' : '#ccc', height: 1 }} />
                                        <div
                                            style={{ width: '3vh', height: '3vh', background: currentphase == 'second' || currentphase == 'third' ? 'var(--primary)' : '#ccc', borderRadius: 100 }}
                                        ></div>
                                        <div style={{ flex: 1, background: currentphase == 'third' ? 'var(--primary)' : '#ccc', height: 1 }} />
                                        <div style={{ width: '3vh', height: '3vh', background: currentphase == 'third' ? 'var(--primary)' : '#ccc', borderRadius: 100 }}></div>
                                    </div>
                                    <div className="row m-0 w-100 mt-2 mb-4 primaryfontmedium">
                                        <p
                                            class={currentphase == 'second' ? 'm-0 p-0 text-primaryhover' : 'm-0 p-0'}
                                            style={{ color: 'var(--primary)' }}
                                            onClick={() => {
                                                if (currentphase == 'second') {
                                                    setcurrentphase('first');
                                                }
                                            }}
                                        >
                                            الخطوة الاولى
                                        </p>
                                        <p
                                            class={currentphase == 'third' ? 'm-0 pb-0 text-center text-primaryhover pl-5' : 'm-0 p-0 text-center pl-5'}
                                            style={{ flex: 1, color: currentphase == 'second' || currentphase == 'third' ? 'var(--primary)' : '#ccc' }}
                                        >
                                            الخطوة الثانيه
                                        </p>
                                        <p
                                            class="m-0 p-0"
                                            style={{
                                                color: currentphase == 'third' ? 'var(--primary)' : '#ccc',
                                            }}
                                        >
                                            تهانينا
                                        </p>
                                    </div>
                                    {currentphase == 'first' && (
                                        <>
                                            {phonenumberAlreadyexists && (
                                                <div
                                                    class="col-lg-12 d-flex justify-content-start mb-3 mt-3 pt-3 pb-3 pl-5 pr-5"
                                                    style={{ background: '#f7d7da', borderRadius: 5, border: '2px solid #edd9dd' }}
                                                >
                                                    <p class="m-0 p-0 primaryfontmedium" style={{ fontSize: '16px', color: '#83464b', textAlign: 'right' }}>
                                                        تمت مشاركتك فى جائزة هذا الشهر، يرجى المشاركة فى جائزة الشهر القادم
                                                    </p>
                                                </div>
                                            )}
                                            <div class="col-lg-12 d-flex justify-content-start mb-1">
                                                <p class="m-0 p-0 primaryfontregular" style={{ fontSize: '16px', color: '#1d8649' }}>
                                                    سؤال الشهر:
                                                </p>
                                            </div>
                                            <div class="col-lg-12 d-flex justify-content-start flex-column text-black" style={{ fontSize: '24px' }}>
                                                <p class="m-0 p-0 d-flex justify-content-start text-right primaryfontregular">{FetchWebsiteinfoQuery.data.data.currentquestion.question}</p>
                                            </div>
                                            <div class="col-lg-12 d-flex justify-content-start mt-3">
                                                <p class="m-0 p-0 primaryfontregular" style={{ fontSize: '16px', color: '#1d8649' }}>
                                                    الإجابة هي:
                                                </p>
                                            </div>
                                            <div class={' col-lg-12 mt-3 '}>
                                                <div class={generalstyles.form_control + ' pr-1 pl-2 '}>
                                                    <select
                                                        class={generalstyles.form_control + ' p-0 primaryfontmedium '}
                                                        value={answerpayload.answerid}
                                                        onChange={(event) => {
                                                            var tempanswerpayload = { ...answerpayload };
                                                            tempanswerpayload.answerid = event.target.value?.toString();
                                                            var answertext = '';
                                                            FetchWebsiteinfoQuery.data.data.currentquestion.answers.forEach(function (item) {
                                                                if (item.id == event.target.value) {
                                                                    answertext = item.answertext;
                                                                }
                                                            });
                                                            tempanswerpayload.answertext = answertext;
                                                            setanswerpayload({ ...tempanswerpayload });
                                                        }}
                                                        style={{ boxShadow: 'none', fontWeight: 400 }}
                                                    >
                                                        <option selected value="" disabled>
                                                            حدد الإجابة
                                                        </option>
                                                        {FetchWebsiteinfoQuery.data.data.currentquestion.answers.map(function (item, index) {
                                                            return <option value={item.id?.toString()}>{item.answertext}</option>;
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 d-flex justify-content-start mt-3">
                                                <p class="m-0 p-0 primaryfontregular" style={{ fontSize: '16px', color: '#1d8649' }}>
                                                    بياناتك الشخصية:
                                                </p>
                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <div class={generalstyles.form_control + ' w-100 p-0 '}>
                                                    <input
                                                        class={`${generalstyles.form_control} ${generalstyles.form_control_noshadow}` + ' primaryfontmedium lightplaceholder '}
                                                        placeholder={'الاسم الكامل'}
                                                        value={answerpayload.name}
                                                        onChange={(event) => {
                                                            setanswerpayload({ ...answerpayload, name: event.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class={' col-lg-12 mt-2 d-flex '} style={{ direction: 'ltr' }}>
                                                <div class={generalstyles.form_control + ' col-lg-12 mt-2 d-flex pt-1 pb-1 '} style={{ overflow: 'hidden' }}>
                                                    <div style={{ width: '70px', borderRight: '1px solid rgb(192 192 192 / 28%)', borderRadius: '13px' }} class="d-flex justify-content-center">
                                                        <input
                                                            placeholder={'+966'}
                                                            style={{ width: '70px', border: 'none', color: '#000' }}
                                                            class={generalstyles.blackplaceholder + ' robotoregular pb-0 '}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div class="d-flex align-items-center pl-2">
                                                        <p class="robotoregular m-0 pb-0 pt-sm-05" style={{ fontSize: '14.5px', fontWeight: 600, paddingTop: 1.35 }}>
                                                            5
                                                        </p>
                                                        <input
                                                            class={generalstyles.lightplaceholder + ' pr-1 robotoregular w-100 h-100 allcentered pb-0 '}
                                                            value={answerpayload.phone}
                                                            placeholder={'00000000'}
                                                            maxlength="8"
                                                            onChange={(event) => {
                                                                if (event.target.value.length == 0 || isNumber(event.target.value)) {
                                                                    setanswerpayload({ ...answerpayload, phone: event.target.value });
                                                                }
                                                            }}
                                                            type={'text'}
                                                            style={{ color: '#000', border: 'none', paddingLeft: 2, fontWeight: 600, fontSize: '15px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    paddingRight: '2.5rem',
                                                    margin: 'auto',
                                                    paddingLeft: '2.5rem',
                                                    paddingTop: '5px',
                                                    marginTop: '2rem',
                                                    border: 'solid white 0.5px',
                                                    borderRadius: '25px',
                                                    boxShadow: '-3px 3px 5px grey',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    navigator.clipboard.writeText('@bandr_alyabsi');
                                                    setIsOpen(true);
                                                }}
                                                onMouseLeave={() => {
                                                    setIsOpen(false);
                                                }}
                                            >
                                                <FaTwitter size={30} style={{ color: '#1DA1F2', display: 'flex' }} />

                                                <p class="primaryfontregular mr-2 ml-2  " style={{ fontSize: '1rem', width: '1wh', color: 'black' }}>
                                                    تابع
                                                </p>
                                                <p class="primaryfontregular  " id="props-basic" style={{ fontSize: '1rem', letterSpacing: '0.02rem', color: 'black' }}>
                                                    bandr_alyabsi@
                                                </p>
                                                <Tooltip anchorId="props-basic" content="تم النسخ" place="left" variant="success" events={['click']} isOpen={isOpen} data-tooltip-delay-hide={6000} />
                                            </div>
                                            <div class="col-lg-12 d-flex align-items-center mt-3" style={{ fontSize: '13px' }}>
                                                <Checkbox
                                                    label="label"
                                                    size="small"
                                                    sx={{
                                                        color: 'var(--primary)',
                                                        '&.Mui-checked': {
                                                            color: 'var(--secondary)',
                                                        },
                                                    }}
                                                    defaultChecked={answerpayload.istermsaccepted == 1 ? true : false}
                                                    onChange={(event) => {
                                                        if (answerpayload.istermsaccepted == 1) {
                                                            setanswerpayload({ ...answerpayload, istermsaccepted: 0 });
                                                        } else {
                                                            setanswerpayload({ ...answerpayload, istermsaccepted: 1 });
                                                        }
                                                    }}
                                                />
                                                <a href="/terms" target="_blank" class="cursor-pointer text-primaryhover primaryfontlight" style={{ color: '#999999' }}>
                                                    أوافق على <span class="a_span">الشروط والأحكام</span>
                                                </a>
                                            </div>
                                        </>
                                    )}
                                    {currentphase == 'second' && (
                                        <>
                                            <div class="col-lg-12 d-flex justify-content-start mt-3">
                                                <p class="m-0 p-0 primaryfontmedium" style={{ fontSize: '16px', color: '#1d8649' }}>
                                                    تاكيد بيانتك الشخصية :
                                                </p>
                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <div class={generalstyles.form_control + ' w-100 p-0 '}>
                                                    <input
                                                        class={`${generalstyles.form_control} ${generalstyles.form_control_noshadow}` + ' primaryfontmedium lightplaceholder '}
                                                        placeholder={'الاسم الكامل'}
                                                        value={answerpayload.name2}
                                                        onChange={(event) => {
                                                            setanswerpayload({ ...answerpayload, name2: event.target.value });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div class={' col-lg-12 mt-2 d-flex '} style={{ direction: 'ltr' }}>
                                                <div class={generalstyles.form_control + ' col-lg-12 mt-2 d-flex '} style={{ overflow: 'hidden' }}>
                                                    <div style={{ width: '70px', borderRight: '1px solid rgb(192 192 192 / 28%)', borderRadius: '13px' }} class="d-flex justify-content-center">
                                                        <input
                                                            placeholder={'+966'}
                                                            style={{ width: '70px', border: 'none', color: '#000' }}
                                                            class={generalstyles.blackplaceholder + ' robotoregular pb-0 '}
                                                            disabled={true}
                                                        />
                                                    </div>
                                                    <div class="d-flex align-items-center pl-2">
                                                        <p class="robotoregular m-0 pb-0 pt-sm-05" style={{ fontSize: '14.5px', fontWeight: 600, paddingTop: 1.35 }}>
                                                            5
                                                        </p>
                                                        <input
                                                            class={generalstyles.lightplaceholder + ' pr-1 robotoregular w-100 h-100 allcentered pb-0 '}
                                                            value={answerpayload.phone2}
                                                            placeholder={'00000000'}
                                                            maxlength="8"
                                                            onChange={(event) => {
                                                                if (event.target.value.length == 0 || isNumber(event.target.value)) {
                                                                    setanswerpayload({ ...answerpayload, phone2: event.target.value });
                                                                }
                                                            }}
                                                            type={'text'}
                                                            style={{ color: '#000', border: 'none', paddingLeft: 2, fontWeight: 600, fontSize: '15px' }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {currentphase == 'third' && (
                                        <div class="col-lg-12 d-flex allcentered flex-column mt-5">
                                            <FaRegCheckCircle size={65} class="mb-1" style={{ color: '#1d8649' }} />
                                            <p class="m-0 p-0 primaryfontregular mb-1 pb-1" style={{ fontSize: '25px', color: '#218847' }}>
                                                تم استلام إجابتك بنجاح
                                            </p>
                                            <div class="col-lg-12 d-flex align-items-center justify-content-center pb-1">
                                                <Follow username={'bandr_alyabsi'} options={{ lang: 'ar', size: 'large', showCount: 'false' }} />
                                            </div>
                                            <button
                                                class={`${generalstyles.formbtn2}` + ' allcentered primaryfontregular mt-1 '}
                                                onClick={() => {
                                                    history.push('/home');
                                                }}
                                                style={{ fontSize: '18px' }}
                                            >
                                                شكرا
                                            </button>
                                        </div>
                                    )}
                                    {currentphase != 'third' && (
                                        <div class="col-lg-12 allcentered mt-4 mb-4">
                                            <button
                                                class={`${generalstyles.formbtn}` + ' allcentered primaryfontregular '}
                                                style={{ fontSize: '18px' }}
                                                onClick={() => {
                                                    if (answerpayload.istermsaccepted == 1) {
                                                        if (answerpayload.name.length != 0) {
                                                            if (answerpayload.phone.length == 8) {
                                                                if (answerpayload.answerid.length != 0) {
                                                                    if (currentphase == 'first') {
                                                                        setcurrentphase('second');
                                                                    } else {
                                                                        if (answerpayload.name2 == answerpayload.name && answerpayload.phone == answerpayload.phone2) {
                                                                            var tempanswerpayload = { ...answerpayload };
                                                                            tempanswerpayload.phone = '+9665' + tempanswerpayload.phone;
                                                                            SubmitAnswerMutation.mutate(tempanswerpayload);
                                                                        } else {
                                                                            NotificationManager.warning('', 'Phone number and name doesnt match');
                                                                        }
                                                                    }
                                                                } else {
                                                                    NotificationManager.warning('', 'Please choose an answer');
                                                                }
                                                            } else {
                                                                NotificationManager.warning('', 'Please enter correct phone number');
                                                            }
                                                        } else {
                                                            NotificationManager.warning('', 'Please enter your name');
                                                        }
                                                    } else {
                                                        NotificationManager.warning('', 'Please accept terms and conditions');
                                                    }
                                                }}
                                                type={'button'}
                                                disabled={SubmitAnswerMutation.isLoading}
                                            >
                                                {!SubmitAnswerMutation.isLoading && <span>إرسال</span>}
                                                {SubmitAnswerMutation.isLoading && <CircularProgress color={'white'} width="20px" height="20px" />}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Subscribe;
